import React, { useEffect } from 'react';
import { useGameObject } from '../../store/GameContext';
import './PickTeam.css';
import { Teams } from '@magicyard/doodledash-game/src/Types';
import { track } from '@magicyard/shared/src/localAnalytics';
import { pickTeamEnter, playOneshot, pops, useOneshot, useRandomOneshot } from '../../AudioManager';
import { randomChoice } from '@magicyard/utils/numberUtils';

export const PickTeam = () => {
  useEffect(() => track('Teams choice phase loaded'), []);
  const { G } = useGameObject();

  useRandomOneshot(pops, G.teamsForPlayers.red.length + '' + G.teamsForPlayers.blue.length);
  return (
    <div className={'pick_team-root'}>
      <TeamBanner color={'red'} showEmpty={true} />
      <TeamBanner color={'blue'} showEmpty={true} />
    </div>
  );
};

export const TeamBanner = ({
  color,
  score,
  winner,
  showEmpty,
  showAll,
}: {
  color: Teams;
  score?: number;
  winner?: boolean;
  showEmpty?: boolean;
  showAll?: boolean;
}) => {
  const { G } = useGameObject();
  const allFaces = showEmpty ? ['0', '1', '2', '3', '4'] : G.teamsForPlayers[color];
  const bottomAnimationDelay = 2 + (allFaces.length * 1) / 3 + 1 / 3;
  useOneshot(pickTeamEnter);
  return (
    <div className={'pick_team-title_root'}>
      <div className={`pick_team-title pick_team-title_${color}`} />
      <div className={'pick_team-paper'}>
        <div className={'pick_team-paper_body'}>
          {showAll
            ? G.players.map((p, i) => (
                <div style={{ width: 150, height: 150, position: 'relative' }} key={p.id}>
                  <PlayerWithFrameAndPop bgImage={p.avatarUrl} i={i} initialWait={2} />
                  <div style={{ position: 'absolute', bottom: -30, width: '100%', textAlign: 'center' }}>{p.name}</div>
                </div>
              ))
            : allFaces.map((p, i) => {
                const pId = G.teamsForPlayers[color][i];
                const player = pId === undefined ? undefined : G.players[+pId];
                const bgImage = player?.avatarUrl;
                return (
                  <div style={{ width: 150, height: 150, position: 'relative' }} key={p}>
                    <PlayerWithFrameAndPop bgImage={bgImage} i={i} initialWait={2} />
                    {player && (
                      <div style={{ position: 'absolute', bottom: -30, width: '100%', textAlign: 'center' }}>
                        {player.name}
                      </div>
                    )}
                  </div>
                );
              })}
          {score !== undefined && (
            <div className={'pick_team-score'} style={{ animationDelay: bottomAnimationDelay + 's' }}>
              {score}
            </div>
          )}
          {winner === true && (
            <div className={`pick_team-winner`} style={{ animationDelay: bottomAnimationDelay + 's' }}>
              <div className={`pick_team-winner-${color}`} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export const PlayerWithFrameAndPop = ({
  bgImage,
  i,
  initialWait,
}: {
  bgImage?: string;
  i: number;
  initialWait: number;
}) => {
  useEffect(() => {
    const id = window.setTimeout(() => playOneshot(randomChoice(pops)), (initialWait + i / 3) * 1000);
    return () => {
      window.clearTimeout(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      className={`pick_team-player_frame`}
      key={i}
      style={{
        animationDelay: initialWait + i / 3 + 's',
      }}
    >
      <div
        className={'pick_team-player'}
        key={bgImage}
        style={{
          backgroundImage: bgImage === undefined ? undefined : `url(${bgImage})`,
        }}
      />
    </div>
  );
};
