import React from 'react';
import './DrawingContainer.css';
import { useGameObject } from '../../store/GameContext';
import { PlayerID } from 'boardgame.io';

export const DrawingContainer = ({
  amountOfDrawings,
  playerID,
  children,
  className,
}: {
  amountOfDrawings: number;
  playerID: PlayerID;
  children: React.ReactNode;
  className?: string;
}) => {
  const { G } = useGameObject();
  return (
    <>
      <div className={`drawing-container_body ${className ?? ''}`} style={getDrawingAltWrapperSize(amountOfDrawings)}>
        <div className={`drawing-container_top drawing-container_top-${G.activePlayer.team}`} />
        {children}
      </div>
    </>
  );
};

const getDrawingAltWrapperSize = (drawCount: number) => {
  const heightRatio = 50 / 32;
  if (drawCount < 4) {
    return { height: 500, width: 320 };
  }
  // 65 will reduce one width of a drawing for every new drawing added
  const width = 320 - (drawCount - 4) * 40;
  return {
    height: heightRatio * width,
    width: width,
  };
};
