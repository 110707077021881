import React from 'react';
import './StageRoot.css';
import SentencePuzzle from '../../components/SentencePuzzle/SentencePuzzle';
import { Title } from '../Title';

export const StageRoot = ({
  children,
  title,
  sentence,
}: {
  children?: React.ReactNode;
  title: string;
  sentence: string;
}) => {
  return (
    <div className={'stage-root_root'}>
      {title !== '' ? <Title title={title} /> : null}
      <SentencePuzzle sentence={sentence} className={'stage-root_sentence'} />
      {children}
    </div>
  );
};
