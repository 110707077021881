import React, { useState } from 'react';
import { Display, YardWithQueue } from '@magicyard/shared/platform/lib/api';
import './QueueScreen.css';
import { QrSign } from './QrSign';
import useInterval from './hooks/useInterval';
import { BaseController } from '@magicyard/shared/platform/hooks/usePlatformControllerTypes';

interface QueueScreenProps {
  yard: YardWithQueue;
  display: Display;
}

export const QueueScreen = ({ yard, display }: QueueScreenProps) => {
  const [dots, setDots] = useState('.');
  const queue = yard.queue;

  useInterval(() => {
    setDots((d) => (d.length === 3 ? '.' : d + '.'));
  }, 1000);

  const allPlayers = queue.yards.reduce<BaseController[]>((acc, yard) => acc.concat(yard.controllers), []);
  const waitingFor = 5 - allPlayers.length;

  return (
    <div className={'queue-screen_root'}>
      <div className={'queue-screen_white-bg'} />
      <div className={'queue-screen_red-carpet'} />
      <div className={'queue-screen_red-rope'} />
      <div className={'queue-screen_red-rope-shade'} />
      <div className={'queue-screen_arrow'} />
      <QrSign display={display} className={'queue-screen_qr'} size={300} />
      <div className={'queue-screen_text-container'}>
        <div className={'queue-screen_queueing-title'}>Queuing up{dots}</div>
        <div className={'queue-screen_queueing-subtitle'}>
          finding {waitingFor} more {waitingFor === 1 ? 'player' : 'players'}
        </div>
      </div>
      <div className={'queue-screen_faces-container'}>
        {/*<YardPart controllers={allPlayersWithDummies} voiceChatEnabled={false} key={1} startAnimating={true} test={}/>*/}
      </div>
    </div>
  );
};
