import React, { useEffect } from 'react';
import './RoundAnnouncement.css';
import { useGameObject } from '../store/GameContext';
import { getActivePlayerId } from '@magicyard/doodledash-game/src/Types';
import { track } from '@magicyard/shared/src/localAnalytics';
import { roundAnnouncementMusic, useOneshot } from '../AudioManager';
import { calcAmountOfRounds } from '@magicyard/doodledash-game/src/Game';

export const RoundAnnouncement = () => {
  useOneshot(roundAnnouncementMusic);
  const { G } = useGameObject();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => track('Round indication shown', { round: G.successfulGuessesForRound.length }), []);
  return (
    <div className={'round_announcement-root'}>
      <div className={'round_announcement-banner'}>
        {G.smallMode.bonusRoundCount > 0
          ? `Bonus round ${G.smallMode.bonusRoundCount}!`
          : `Round ${G.successfulGuessesForRound.length} / ${calcAmountOfRounds(G)}`}
      </div>
      <div className={'round_announcement-player'}>
        {G.players[+getActivePlayerId(G)].name} {G.smallMode.bonusRoundCount > 0 ? 'draws again!' : 's turn to draw'}
      </div>
    </div>
  );
};
