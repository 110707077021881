import { useGameObject } from '../../store/GameContext';
import React, { useEffect, useState } from 'react';
import './WinnerTransition.css';
import { TeamBanner } from './PickTeam';
import { calcScore } from '@magicyard/doodledash-game/src/utils/moves.util';
import { ConfettiInternal } from './ActiveDrawing/DisplayActiveDrawingSimultaneous';
import { track } from '@magicyard/shared/src/localAnalytics';
import { Phases, SMALL_ROUNDS_PER_PLAYER } from '@magicyard/doodledash-game/src/Game';
import { getServerTimePassedMs, useMillisReachedFromServer } from '@magicyard/doodledash-shared/hooks/IntervalHooks';
import { HighScore } from './HighScore';

export const WinnerTransition = () => {
  useEffect(() => {
    track('Winner Phase Started');
  }, []);
  const { G } = useGameObject();
  const score = calcScore(G);
  const isTie = score.red === score.blue;
  return (
    <div className={'winner-transition_root'}>
      <ConfettiInternal />
      {!isTie ? (
        <TeamBanner color={score.red > score.blue ? 'red' : 'blue'} winner={true} />
      ) : (
        <>
          <div
            style={{
              fontSize: 80,
              textAlign: 'center',
            }}
          >
            It's a TIE! <br /> Play again to see who wins!
          </div>
        </>
      )}
    </div>
  );
};

export const WinnerSmall = () => {
  const [showHighScore, setShowHighScore] = useState(false);
  useEffect(() => {
    track('Winner Phase Started');
  }, []);
  const { G, ctx } = useGameObject();
  const score = calcScore(G);
  const totalToLetter = (total: number) => {
    const t = total / 10;
    if (t < G.players.length * 3.5 * SMALL_ROUNDS_PER_PLAYER) {
      return 'F';
    } else if (t < G.players.length * 6.5 * SMALL_ROUNDS_PER_PLAYER) {
      return 'C';
    } else if (t < G.players.length * 8.5 * SMALL_ROUNDS_PER_PLAYER) {
      return 'B';
    } else if (t < G.players.length * 11 * SMALL_ROUNDS_PER_PLAYER) {
      return 'A';
    } else if (t < G.players.length * 13.5 * SMALL_ROUNDS_PER_PLAYER) {
      return 'S';
    } else {
      return 'S+';
    }
  };

  useMillisReachedFromServer({
    serverTimePassedMs: getServerTimePassedMs(G.phaseStartTime, ctx.phase as Phases, false),
    millisWithCallback: {
      5000: () => setShowHighScore(true),
    },
  });
  return (
    <div className={'winner-transition_root'}>
      {showHighScore ? (
        <HighScore />
      ) : (
        <>
          <TeamBanner color={'red'} score={score.red + score.blue} showAll={true} />
          <div className={'winner-transition_letter-container'}>
            <div className={'winner-transition_letter'}>{totalToLetter(score.red + score.blue)}</div>
          </div>
        </>
      )}
    </div>
  );
};
