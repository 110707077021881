import React, { useEffect, useMemo } from 'react';
import { Phases } from '@magicyard/doodledash-game/src/Game';
import background from '../assets/doodleDash/background.jpg';
import { useGameObject } from '../store/GameContext';

import { DisplayActiveDrawingSimultaneous } from './Stages/ActiveDrawing/DisplayActiveDrawingSimultaneous';
import { WinnerSmall, WinnerTransition } from './Stages/WinnerTransition';
import { Title } from './Title';
import { Display } from '@magicyard/shared/platform/lib/api';
import { PickTeam } from './Stages/PickTeam';
import { RoundAnnouncement } from './RoundAnnouncement';
import { Scoring, ScoringSmall } from './Scoring';
import { ScoringTransition } from './ScoringTransition';
import './GameManager.css';
import { DriftDbSocketProvider as SocketProvider } from '@magicyard/shared/src/DriftDbSocketContext';
import { AudioManager } from '../AudioManager';

interface PhaseData {
  mainPage: () => JSX.Element;
  transition: (() => JSX.Element) | null;
  hasQr: boolean;
}

const Sync = () => {
  const { moves } = useGameObject();
  useEffect(() => {
    moves.endSync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Title
      title={'Starting...'}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        color: 'white',
      }}
    />
  );
};

const StageManager = ({ display, link }: { display: Display | undefined; link: string }) => {
  const { ctx, G } = useGameObject();
  const { isTransition } = G;
  const phase = ctx.phase as Phases;
  const phaseToScreen: Record<Phases, PhaseData> = useMemo(
    () => ({
      [Phases.Sync]: {
        mainPage: Sync,
        transition: null,
        hasQr: true,
      },
      [Phases.PickTeam]: {
        mainPage: PickTeam,
        transition: null,
        hasQr: true,
      },
      [Phases.Playing]: {
        mainPage: DisplayActiveDrawingSimultaneous,
        transition: RoundAnnouncement,
        hasQr: true,
      },
      [Phases.Scoring]: {
        mainPage: !G.isSmall ? Scoring : ScoringSmall,
        transition: ScoringTransition,
        hasQr: true,
      },
      [Phases.GameEnd]: {
        mainPage: !G.isSmall ? WinnerTransition : WinnerSmall,
        transition: null,
        hasQr: false,
      },
    }),
    [G.isSmall]
  );
  const curr = phaseToScreen[phase ?? Phases.GameEnd];
  console.log(curr, isTransition, isTransition && curr.transition !== null);
  return (
    <>
      <AudioManager phase={phase} isTransition={isTransition && curr.transition !== null} />
      <div
        style={{
          width: '100%',
          height: '100%',
          backgroundImage: `url(${background})`,
        }}
      >
        {curr.hasQr && display !== undefined ? (
          <div className={'game_manager-qr'}>
            <div className={'game_manager-qr_body'}>
              {link}
              <br />
              room code: {display.code}
            </div>
          </div>
        ) : null}
        <SocketProvider socketUrl={G.drawingSocketUrl}>
          {isTransition && curr.transition !== null ? <curr.transition /> : <curr.mainPage key={ctx.phase} />}
        </SocketProvider>
      </div>
    </>
  );
};

export default StageManager;
