import React, { useEffect } from 'react';
import { useGameObject } from '../store/GameContext';
import { StageRoot } from './Stages/StageRoot';
import { getActivePlayerId } from '@magicyard/doodledash-game/src/Types';
import btnSuccess from '../assets/doodleDash/btnSuccess.webp';
import './ScoringTransition.css';
import { MAX_WORDS } from '@magicyard/doodledash-game/src/words';
import { drumrole, useOneshot } from '../AudioManager';
import { CharacterWithSpeech } from './Stages/ActiveDrawing/DisplayActiveDrawingSimultaneous';
import { getActiveGuessesAndPlayer } from '@magicyard/doodledash-game/src/Game';

export const ScoringTransition = () => {
  const { G, moves } = useGameObject();
  const guessesForPlayer = getActiveGuessesAndPlayer(G).guesses.slice(
    G.smallMode.bonusRoundCount * MAX_WORDS,
    getActiveGuessesAndPlayer(G).guesses.length
  );
  useEffect(() => {
    return () => {
      moves.finishScoringEarlyIfNeeded();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useOneshot(drumrole);
  return (
    <StageRoot sentence={''} title={''}>
      <div className={`pre-game-complete-sentence_title-pic-${G.activePlayer.team}`} style={{ top: 500, left: 500 }} />
      <CharacterWithSpeech
        team={G.activePlayer.team}
        avatarUrl={G.players[+getActivePlayerId(G)].avatarUrl}
        text={`${guessesForPlayer.length === 0 ? 'Bad job' : 'Well done'} ${G.players[+getActivePlayerId(G)].name}`}
      />
      <div style={{ marginTop: -100 }}>
        {guessesForPlayer.map((g, i) => (
          <div className={'scoring_transition-btn_container'} style={{ animationDelay: `${i * 300 + 2000}ms` }} key={i}>
            <div
              style={{
                backgroundImage: `url(${btnSuccess})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                width: 340,
                height: 100,
                color: 'white',
                fontSize: g.word.length > 15 ? 40 : 50,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {g.word}
            </div>
            +{g.score}
          </div>
        ))}
      </div>
    </StageRoot>
  );
};
