import { getControllerUrlConnectDisplay } from '@magicyard/utils';
import { Display, generateRandomControllers, Profile, YardWithGame } from '@magicyard/shared/platform/lib/api';
import { URL_SHORTENING_API } from '@magicyard/shared/platform/lib/urlShortener';

import React, { useEffect, useRef } from 'react';
import './YardAndDisplay.css';
import { track, updateAnalyticsData } from '@magicyard/shared/src/analytics';
import { AskToUsePhoneOverlay } from './AskToUsePhoneOverlay';
import { pops, useRandomOneshot } from './AudioManager';
import { BaseController } from '@magicyard/shared/platform/hooks/usePlatformControllerTypes';
import { useSamsungBack } from './App';

interface YardAndDisplayProps {
  yard: YardWithGame;
  display: Display;
  test: 'A' | 'B';
  link: string;
}

export const YardAndDisplay = ({ yard, display, test, link }: YardAndDisplayProps) => {
  useEffect(() => {
    updateAnalyticsData({ displayId: display.id, yardId: yard.id });
  }, [display, yard]);

  useEffect(() => {
    if (yard.controllers.length !== 0) {
      updateAnalyticsData({ numOfPlayers: yard.controllers.length, voiceChatAvailable: yard.voiceChatState !== null });
      track('Yard With Players Loaded');
    }
  }, [yard.controllers.length, yard.voiceChatState]);

  useEffect(() => track('Yard And QR Loaded'), []);
  useSamsungBack('Are you sure you want to exit?', () => {
    window.history.back();
  });
  return (
    <>
      <AskToUsePhoneOverlay />
      <div className={'yard-and-display_root'}>
        <LeftSide test={test} />
        <div className={`yard-and-display_qr-child-container yard-and-display_qr-child-container-${test}`}>
          <div
            className={'yard-and-display_qr'}
            style={{
              backgroundImage: `url(${URL_SHORTENING_API}/qr?text=${encodeURIComponent(
                getControllerUrlConnectDisplay(display.id)
              )})`,
            }}
          />
          <div className={'yard-and-display_code'}>
            <span style={{ fontSize: 37, fontWeight: 200 }}>or, on your phone go to</span>
            <br />
            <span style={{ fontSize: 42, textDecoration: 'underline' }}>{link}</span>
            <br />
            Room code: {display.code}
          </div>
        </div>
        <YardPart
          test={test}
          controllers={yard.controllers.concat(generateRandomControllers(0))}
          startAnimating={true}
          voiceChatEnabled={false}
        />
        {yard.controllers.length > 0 && (
          <div className={`yard-and-display_buttons-container-${test}`}>
            <BtnWithTitle
              text={'Co op'}
              title={'2 - 3 players'}
              selected={yard.controllers.length > 1 && yard.controllers.length < 4}
            />
            <BtnWithTitle text={'Team vs Team'} title={'4 - 10 players'} selected={yard.controllers.length > 3} />
          </div>
        )}
      </div>
    </>
  );
};

const LeftSide = ({ test }: { test: 'A' | 'B' }) => {
  if (test === 'A') {
    return (
      <>
        <div className={'yard-and-display_title-img'} />
        <div className={'yard-and-display_title-text'}>Your phone will become the controller</div>
        <div className={'yard-and-display_mascot'} />
      </>
    );
  } else {
    return (
      <>
        <div className={'yard-and-display_title-img-b'} />
        <div className={'yard-and-display_mascot-b'} />
      </>
    );
  }
};

const BtnWithTitle = ({ text, title, selected }: { text: string; title: string; selected: boolean }) => {
  return (
    <div className={'yard-and-display_button-with-title-container'}>
      {title}
      <div className={`yard-and-display_button ${selected ? 'yard-and-display_selected-button' : ''}`}>{text}</div>
    </div>
  );
};

export const YardPart = ({
  controllers,
  voiceChatEnabled,
  className,
  test,
}: {
  controllers: Array<BaseController & { dummyData?: { isOptional: boolean } }>;
  voiceChatEnabled: boolean | null;
  className?: string;
  startAnimating: boolean;
  test: 'A' | 'B';
}) => {
  useRandomOneshot(pops, controllers.length === 0 ? null : controllers.length);
  return (
    <div className={className}>
      {controllers.map((c, index) => {
        return (
          <div
            key={c.profile.id}
            style={{ position: 'absolute', ...indexToPosition(index, test), textAlign: 'center' }}
          >
            <PlayerSelfie profile={c.profile} dummyData={c.dummyData} />
          </div>
        );
      })}
    </div>
  );
};

export const PlayerSelfie = ({ profile, dummyData }: { profile: Profile; dummyData?: { isOptional: boolean } }) => {
  const delay = useRef(Math.random() * 3);
  return (
    <div
      className={'pick_team-player_frame'}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <div
        className={`selfie ${dummyData?.isOptional === true ? 'selfie_optional' : ''}`}
        style={{ backgroundImage: `url(${profile.avatarUrl})`, animationDelay: delay + 's' }}
      />
      <div
        className={`selfie_name 
        ${dummyData !== undefined ? 'selfie_name-dummy ' : ''}
        ${dummyData?.isOptional === true ? 'selfie_name-optional' : ''}`}
      >
        {profile.name}
        <span className={dummyData !== undefined ? 'selfie_name-dummy-spacing' : ''}>
          {dummyData?.isOptional === true ? '\nOptional' : dummyData?.isOptional === false ? '\nPlayer' : ''}
        </span>
      </div>
    </div>
  );
};

const indexToPosition = (index: number, test: 'A' | 'B') => {
  const left = (index % 5) * 200 + (test === 'A' ? 1200 : 1950);
  const top = index < 5 ? '32%' : '50%';
  return {
    left,
    top,
  };
};
