import React, { useCallback, useEffect, useState } from 'react';
import { Phases } from '@magicyard/doodledash-game/src/Game';
import Theme from '../assets/audio/theme.mp3';
import PlayingMusic from '../assets/audio/playing.mp3';
import EndGameMusic from '../assets/audio/endGameMusic.mp3';
import RoundAnnouncementMusic from '../assets/audio/Person To Draw Sound.mp3';
import pop1 from '../assets/audio/Varied Pop 1.mp3';
import pop2 from '../assets/audio/Varied Pop 2.mp3';
import pop3 from '../assets/audio/Varied Pop 3.mp3';
import pop4 from '../assets/audio/Varied Pop 4.mp3';
import pop5 from '../assets/audio/Varied Pop 5.mp3';
import swish1 from '../assets/audio/Drawing Card Swish.mp3';
import swish2 from '../assets/audio/Drawing Card Swish 2.mp3';
import PickTeamEnter from '../assets/audio/Banner Sound.mp3';
import CorrectGuess from '../assets/audio/Correct Guess.mp3';
import Drumrole from '../assets/audio/drumrole.mp3';
import ClockEnter from '../assets/audio/Clock Slide In.mp3';
import ClockJingle from '../assets/audio/Clock Start Jingle.mp3';
import ClockWarning from '../assets/audio/30 Second Warning.mp3';
import { randomChoice } from '@magicyard/utils/numberUtils';
import { lastFocus, useNativeFocus } from '@magicyard/shared/src/UseNativeFocus';
import { Howl, Howler } from 'howler';

const searchParams = new URLSearchParams(window.location.search);
export const DISABLE_AUDIO = searchParams.get('disable_sound') === '1';
const html5Fallback = searchParams.get('version_name')?.toLowerCase().includes('android') === true;
if (html5Fallback) {
  Howler.usingWebAudio = false;
}

type HowlCompatible = {
  pause: () => void;
  stop: () => void;
  play: () => void;
  playing: () => boolean;
};

const getHowlCompatible = (audio: HTMLAudioElement): HowlCompatible => {
  return {
    play: () => audio.play(),
    pause: () => audio.pause(),
    stop: () => {
      audio.pause();
      audio.currentTime = 0;
    },
    playing: () => !audio.paused,
  };
};

const newMusic = (src: string): HowlCompatible | null => {
  if (DISABLE_AUDIO) {
    return null;
  }
  if (html5Fallback) {
    const audio = new Audio(src);
    audio.loop = true;
    audio.volume = 0.7;
    return getHowlCompatible(audio);
  }
  return new Howl({ src: [src], loop: true, html5: html5Fallback });
};

const newOneshot = (src: string): HowlCompatible | null => {
  if (DISABLE_AUDIO) {
    return null;
  }
  if (html5Fallback) {
    const audio = new Audio(src);
    audio.volume = 0.7;
    return getHowlCompatible(audio);
  }
  return new Howl({ src: [src], html5: html5Fallback });
};

const themeRef = newMusic(Theme);
export const AudioStagesMap: { [key in Phases]: HowlCompatible | null } = {
  [Phases.Sync]: themeRef,
  [Phases.PickTeam]: themeRef,
  [Phases.Playing]: newMusic(PlayingMusic),
  [Phases.Scoring]: themeRef,
  [Phases.GameEnd]: newOneshot(EndGameMusic),
};
export const drumrole = newOneshot(Drumrole);

export const roundAnnouncementMusic = newOneshot(RoundAnnouncementMusic);
export const pops = [newOneshot(pop1), newOneshot(pop2), newOneshot(pop3), newOneshot(pop4), newOneshot(pop5)];
export const swishes = [newOneshot(swish1), newOneshot(swish2)];
export const pickTeamEnter = newOneshot(PickTeamEnter);
export const correctGuess = newOneshot(CorrectGuess);

export const clockWarning = newOneshot(ClockWarning);

export const clockEnter = newOneshot(ClockEnter);
export const clockJingle = newOneshot(ClockJingle);

export const menuMusic = newMusic(Theme);

export const AudioManager = ({ phase, isTransition }: { phase: Phases; isTransition: boolean }) => {
  if (DISABLE_AUDIO) {
    return <></>;
  }
  return <AudioManagerInner phase={phase} isTransition={isTransition} />;
};

export const AudioManagerInner = ({ phase, isTransition }: { phase: Phases; isTransition: boolean }) => {
  const [activeSoundTrack, setActiveSoundTrack] = useState<Phases | null>(null);
  const [showAudioEnabler, setShowAudioEnabler] = useState(false);
  const [isInFocus, setIsInFocus] = useState(true);

  const onBlur = useCallback(() => {
    setIsInFocus(false);
    AudioStagesMap[phase]?.pause();
  }, [phase]);
  const onFocus = useCallback(() => {
    setIsInFocus(true);
    if (AudioStagesMap[phase]?.playing() === false) {
      AudioStagesMap[phase]?.play();
    }
  }, [phase]);

  useNativeFocus(onFocus, onBlur);
  const playAudioIfFocused = useCallback(() => {
    setShowAudioEnabler(false);
    const curr = AudioStagesMap[phase];
    if (isInFocus && curr !== null && !isTransition) {
      return curr.play();
    } else {
      return new Promise(() => undefined);
    }
  }, [isInFocus, isTransition, phase]);

  const turnVolumeDown = useCallback((target: Phases) => {
    const curr = AudioStagesMap[target];
    if (curr === null) {
      return;
    }
    curr.stop();
    //   curr.volume = 0.5;
    //   setTimeout(() => {
    //     curr.volume = 0.2;
    //   }, 700);
    //
    //   setTimeout(() => {
    //     curr.pause();
    //     curr.currentTime = 0;
    //     curr.volume = 0.5;
    //   }, 1500);
  }, []);

  useEffect(() => {
    const shouldPlayNewTrack = activeSoundTrack !== phase && isInFocus && !isTransition;
    const shouldStopOldTrack = activeSoundTrack !== null && (isTransition || shouldPlayNewTrack);
    if (shouldStopOldTrack) {
      try {
        turnVolumeDown(activeSoundTrack);
      } catch (err) {
        console.log(err);
      }
    }
    if (shouldPlayNewTrack) {
      setActiveSoundTrack(phase);
    }
  }, [activeSoundTrack, isInFocus, isTransition, phase, turnVolumeDown]);

  useEffect(() => {
    if (AudioStagesMap[phase]) {
      playAudioIfFocused();
    } else console.log('Could Not find sound track');
  }, [activeSoundTrack, playAudioIfFocused, phase]);

  if (showAudioEnabler) {
    return (
      <button
        id="audio-manager--init"
        style={{ position: 'absolute', top: 10, left: 10, zIndex: 99999 }}
        onClick={playAudioIfFocused}
      >
        Enable Audio
      </button>
    );
  }

  return null;
};

export const stopMusic = () => {
  Object.values(AudioStagesMap).map((x) => x?.pause());
  menuMusic?.pause();
};

export const useOneshot = (oneshot: HowlCompatible | null, key?: string | number | null) => {
  useEffect(() => {
    if (key !== null) {
      playOneshot(oneshot);
    }
  }, [oneshot, key]);
};

export const useRandomOneshot = (oneshot: (HowlCompatible | null)[], key?: string | number | null) => {
  useEffect(() => {
    if (key !== null) {
      playOneshot(randomChoice(oneshot));
    }
  }, [oneshot, key]);
};

export const playOneshot = (oneshot: HowlCompatible | null) => {
  if (oneshot === null) {
    return;
  }

  if (lastFocus) {
    oneshot.play();
  }
};
