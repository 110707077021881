import './HighScore.css';
import React, { useEffect, useMemo } from 'react';
import { useGameObject } from '../../store/GameContext';
import { TeamNamesAndPicsBar } from './ActiveDrawing/DisplayActiveDrawingSimultaneous';
import { calcScore } from '@magicyard/doodledash-game/src/utils/moves.util';

const HIGH_SCORE_KEY = 'high_score_key';

const saveHighScore = (newHighScore: SavedHighScore) => {
  localStorage.setItem(HIGH_SCORE_KEY, JSON.stringify([...getHighScores(), newHighScore]));
};

interface SavedHighScore {
  profiles: Array<{ avatarUrl: string; name: string }>;
  score: number;
  date: number;
}

const getHighScores = (): SavedHighScore[] => {
  const highScores = localStorage.getItem(HIGH_SCORE_KEY);

  return highScores === null ? [] : (JSON.parse(highScores) as SavedHighScore[]);
};

export const HighScore = () => {
  const { G } = useGameObject();
  const score = calcScore(G);

  const latestHighScoreTime = useMemo(() => Date.now(), []);

  const currentHighScore: SavedHighScore = useMemo(
    () => ({
      profiles: G.players,
      date: latestHighScoreTime,
      score: score.red + score.blue,
    }),
    [G.players, latestHighScoreTime, score.blue, score.red]
  );

  useEffect(() => {
    saveHighScore(currentHighScore);
  }, [currentHighScore]);

  const highScores = useMemo(
    () =>
      getHighScores()
        .slice(0, 4)
        .concat(currentHighScore)
        .sort((h0, h1) => (h1.score !== h0.score ? h1.score - h0.score : h1.date - h0.date)),
    [currentHighScore]
  );

  return (
    <div className={'high-score_root'}>
      <div className={'high-score_title'} />
      <div className={'high-score_paper'}>
        <div className={'high-score_paper-body'}>
          {highScores.map((x, i) => {
            const isCurrent = latestHighScoreTime === x.date;
            return (
              <div style={{ transform: `${isCurrent ? 'scale(1.1)' : undefined}` }} key={i}>
                <div key={x.date} className={'high-score_bar-container'} style={{ animationDelay: i * 0.5 + 1 + 's' }}>
                  <div className={'high-score_index'}>{i + 1}</div>
                  <TeamNamesAndPicsBar
                    team={isCurrent ? 'red' : 'blue'}
                    currentlyGuessing={x.profiles}
                    textAfterNames={`scored: ${x.score}`}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
