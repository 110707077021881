import React from 'react';
import './InitialLoading.css';

export const InitialLoading = ({ onClick }: { onClick?: () => void }) => {
  return (
    <div className={'initial-loading_root'} onClick={onClick}>
      {onClick !== undefined ? (
        <>
          <div className={'initial-loading_title'} />
          <div className={'initial-loading_btn'}>Click to start!</div>
        </>
      ) : (
        <>
          <div className={'initial-loading_title'} />
        </>
      )}
    </div>
  );
};
