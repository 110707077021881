import React, { useEffect } from 'react';
import GameManager from '../GameManager/GameManager';
import GameContext from '../store/GameContext';

import { GameObject } from '@magicyard/doodledash-game/src/Game';
import { updateAnalyticsData } from '@magicyard/shared/src/analytics';
import { Display } from '@magicyard/shared/platform/lib/api';
import { useSamsungBack } from '../App';

const Board = (props: GameObject & { display?: Display; onBack: () => void; link: string }) => {
  const { matchID } = props;
  useSamsungBack('Go back to Main Menu?', props.onBack);
  useEffect(() => {
    updateAnalyticsData({ matchId: matchID });
  }, [matchID]);

  return (
    <GameContext.Provider value={props}>
      <GameManager display={props.display} link={props.link} />
    </GameContext.Provider>
  );
};

export default Board;
