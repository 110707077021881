import React, { useEffect } from 'react';
import { useGameObject } from '../../../store/GameContext';
import { getActiveGuessesAndPlayer, Phases } from '@magicyard/doodledash-game/src/Game';
import './DisplayActiveDrawingSimultaneous.css';
import { StageRoot } from '../StageRoot';
import DrawingAlt from '@magicyard/doodledash-shared/components/DrawingAlt';
import { DrawingContainer } from '../../../components/DrawingsView/DrawingContainer';
// @ts-ignore
import { getActivePlayerId } from '@magicyard/doodledash-game/src/Types';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import clock from '../../../assets/doodleDash/clock.webp';
import { waitTimeForPhaseInMs } from '@magicyard/doodledash-game/src/Config';
import { track } from '@magicyard/shared/src/localAnalytics';
import { MAX_WORDS } from '@magicyard/doodledash-game/src/words';
import { didGuessEverythingThisRound } from '@magicyard/doodledash-game/src/utils/moves.util';
import { clockEnter, clockJingle, clockWarning, correctGuess, playOneshot, useOneshot } from '../../../AudioManager';
import { swishes, useRandomOneshot } from '@magicyard/doodledash-display/src/AudioManager';
import { PlayerWithFrameAndPop } from '../PickTeam';

export const ConfettiInternal = ({ topLeft }: { topLeft?: boolean }) => {
  return <div className={`confetti-internal ${topLeft ? 'confetti-internal_top_left' : ''}`} />;
};

export const DisplayActiveDrawingSimultaneous = () => {
  const { G, ctx } = useGameObject();
  const isDrawing = G.currentDrawingWord !== null;
  const successKey = getActiveGuessesAndPlayer(G).guesses.length;
  const successThisRound = successKey - MAX_WORDS * G.smallMode.bonusRoundCount;
  useEffect(() => {
    return () => {
      track(`Round Ended ${didGuessEverythingThisRound(G) ? 'All Words Guessed' : 'Times Up'}`);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getGuessingOtherThanActive = (team: 'red' | 'blue') =>
    G.teamsForPlayers[team]
      .filter((pid) => pid !== getActivePlayerId(G))
      .map((pid) => {
        return G.players[+pid];
      });

  const currentlyGuessing = G.isSmall
    ? getGuessingOtherThanActive('red').concat(getGuessingOtherThanActive('blue'))
    : getGuessingOtherThanActive(G.activePlayer.team);

  useEffect(() => {
    playOneshot(clockEnter);
    const ids = [
      window.setTimeout(() => playOneshot(clockJingle), 1000),
      window.setTimeout(() => playOneshot(clockWarning), 45000),
    ];

    return () => {
      ids.map(window.clearTimeout);
    };
  }, []);

  useOneshot(correctGuess, successThisRound === 0 ? null : successKey);
  return (
    <div>
      {successThisRound > 0 && (
        <>
          <ConfettiInternal key={successKey + 'confetti'} />
          <div className={'display-active-drawing-sim_success'} key={successKey}>
            {getActiveGuessesAndPlayer(G).guesses[successKey - 1].word}
            <br /> {successThisRound} / {MAX_WORDS}
          </div>
        </>
      )}
      <div className={'display-active-drawing-sim_clock-container'} style={{ backgroundImage: `url(${clock})` }}>
        <CountdownCircleTimer
          isPlaying={true}
          duration={waitTimeForPhaseInMs[ctx.phase as Phases] / 1000}
          strokeWidth={8}
          colors={['#004777', '#A30000', '#A30000']}
          colorsTime={[7, 2, 0]}
        >
          {({ remainingTime }) => <div style={{ fontSize: 70 }}>{remainingTime}</div>}
        </CountdownCircleTimer>
      </div>
      <CharacterWithSpeech
        team={G.activePlayer.team}
        avatarUrl={G.players[+getActivePlayerId(G)].avatarUrl}
        text={
          isDrawing
            ? `${G.players[+getActivePlayerId(G)].name} is drawing!`
            : `${G.players[+getActivePlayerId(G)].name} is choosing`
        }
      />
      {currentlyGuessing.length > 0 && (
        <div className={'display-active-drawing-sim_title-container'}>
          <TeamNamesAndPicsBar
            currentlyGuessing={currentlyGuessing}
            team={G.activePlayer.team}
            textAfterNames={`${currentlyGuessing.length === 1 ? 'is ' : 'are '} guessing`}
          />
        </div>
      )}
      {G.currentDrawingWord !== null ? <XXX hint={G.currentDrawingWord.hint} /> : undefined}
    </div>
  );
};

export const TeamNamesAndPicsBar = ({
  currentlyGuessing,
  team,
  textAfterNames,
}: {
  currentlyGuessing: {
    name: string;
    avatarUrl: string;
  }[];
  team: 'red' | 'blue';
  textAfterNames: string;
  rootClassName?: string;
}) => {
  return (
    <div className={`display-active-drawing-sim_title display-active-drawing-sim_title-${team}`}>
      {currentlyGuessing.map((p, i) => {
        return (
          <div className={'display-active-drawing-sim_title-icon'} key={i}>
            <PlayerWithFrameAndPop bgImage={p.avatarUrl} i={i} initialWait={0} />
          </div>
        );
      })}
      <div style={{ marginLeft: 20, fontSize: 45 }}>
        {currentlyGuessing.map((p, i) => {
          return (
            p.name + (i === currentlyGuessing.length - 1 ? ' ' : i === currentlyGuessing.length - 2 ? ' and ' : ' ,')
          );
        })}{' '}
        {textAfterNames}
      </div>
    </div>
  );
};

const XXX = ({ hint }: { hint: string }) => {
  const { G } = useGameObject();
  useRandomOneshot(swishes);
  return (
    <>
      <StageRoot title={''} sentence={''}>
        <DrawingContainer amountOfDrawings={1} playerID={'0'} className={'display-active-drawing-sim_draw'}>
          <div
            className={`display-active-drawing-sim_category display-active-drawing-sim_category-${G.activePlayer.team}`}
          >
            <div
              style={{
                fontSize: hint.length > 10 ? (hint.length > 15 ? '25px' : '30px') : '40px',
                marginTop: hint.length > 10 ? (hint.length > 15 ? '10px' : '5px') : '0',
              }}
            >
              Hint: {hint}
            </div>
          </div>
          <DrawingAlt />
        </DrawingContainer>
      </StageRoot>
    </>
  );
};

export const CharacterWithSpeech = ({
  team,
  avatarUrl,
  text,
}: {
  team: 'red' | 'blue';
  avatarUrl: string;
  text: string;
}) => {
  return (
    <div className={`display-active-drawing-sim_char-container`}>
      <div className={`display-active-drawing-sim_char-${team}`} />
      <div className={'display-active-drawing-sim_char-speech'} key={text}>
        <div className={'display-active-drawing-sim_char-speech-img'}>
          <PlayerWithFrameAndPop bgImage={avatarUrl} i={0} initialWait={0} />
        </div>
        <div
          className={'display-active-drawing-sim_char-speech-text'}
          style={{ fontSize: text.length > 20 ? (text.length > 30 ? 30 : 40) : 50 }}
        >
          {text}
        </div>
      </div>
    </div>
  );
};
