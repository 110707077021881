import React, { useContext, useEffect, useRef } from 'react';
import { Point } from '@magicyard/doodledash-game/src/Types';
import './DrawingAlt.css';
import { SequenceValue } from 'driftdb';
import { DriftDbSocketContext, useDriftDbSocketContext } from '@magicyard/shared/src/DriftDbSocketContext';

interface Data {
  lastPoint: Point;
  force: number;
  to: Point;
  color: string;
}

const DrawingAlt = () => {
  const socket = useDriftDbSocketContext();

  useEffect(() => {
    const onBroadcast = (data: SequenceValue) => {
      const msg = data.value as { event: 'draw' | 'clear' | 'erase'; data: Data };
      if (msg.event === 'draw') {
        draw(msg.data);
      }
      if (msg.event === 'clear') {
        clear();
      }
      if (msg.event === 'erase') {
        erase(msg.data);
      }
    };
    socket.subscribe('broadcast', onBroadcast);
    return () => {
      socket.unsubscribe('broadcast', onBroadcast);
    };
  }, [socket]);

  function clear() {
    ctxRef.current?.clearRect(0, 0, canvasRef.current?.width ?? 0, canvasRef.current?.height ?? 0);
  }

  function draw(data: Data) {
    ctxRef.current!.beginPath();
    ctxRef.current!.moveTo(data.lastPoint.x, data.lastPoint.y);
    ctxRef.current!.lineTo(data.to.x, data.to.y);
    ctxRef.current!.strokeStyle = data.color;
    ctxRef.current!.lineWidth = data.force;
    ctxRef.current!.lineCap = 'round';
    ctxRef.current!.stroke();
    ctxRef.current!.closePath();
  }

  function erase(data: Data) {
    ctxRef.current!.clearRect(
      data.lastPoint.x - data.force / 2,
      data.lastPoint.y - data.force / 2,
      data.force,
      data.force
    );
  }

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const ctxRef = useRef<CanvasRenderingContext2D | null>(null);
  useEffect(() => {
    ctxRef.current = canvasRef.current!.getContext('2d');
    const parent = canvasRef.current!.parentElement!;
    canvasRef.current!.width = parent.clientWidth;
    canvasRef.current!.height = parent.clientHeight;
  }, []);
  return <canvas ref={canvasRef} style={{ width: '100%', height: '100%' }} />;
};

export default DrawingAlt;
