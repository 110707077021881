import { TeamBanner } from './Stages/PickTeam';
import React, { useEffect } from 'react';
import { useGameObject } from '../store/GameContext';
import { calcScore } from '@magicyard/doodledash-game/src/utils/moves.util';
import { track } from '@magicyard/shared/src/localAnalytics';

export const Scoring = () => {
  useEffect(() => {
    track('Scoring Phases Started');
  }, []);
  const { G } = useGameObject();

  const score = calcScore(G);

  return (
    <div className={'pick_team-root'}>
      <TeamBanner color={'red'} score={score.red} />
      <TeamBanner color={'blue'} score={score.blue} />
    </div>
  );
};

export const ScoringSmall = () => {
  useEffect(() => {
    track('Scoring Phases Started');
  }, []);
  const { G } = useGameObject();

  const score = calcScore(G);
  const total = score.blue + score.red;

  return (
    <div className={'pick_team-root'}>
      <TeamBanner color={'red'} score={total} showAll={true} />
    </div>
  );
};
